import React, { useState } from "react"
import { Container, Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import numeral from "numeral"
import Disclaimer from "./disclaimer"

const Error = styled.p`
    display: flex
    width: 250px
    padding: 0.5rem 0
    margin: 0
    font-weight:bold
`
const SubmitButton = styled.button`
    -webkit-appearance:none
    background:transparent
    transition: 0.3s ease
    border: 2px solid #2C5282
    padding: 10px 20px
    border-radius:4px    
    cursor:pointer
`

const MaximumMortgage = () => {
  const [mortgageTerm, setMortgageTerm] = useState("")
  const [paymentFrequency, setPaymentFrequency] = useState("")
  const [amortizationPeriod, setAmortizationPeriod] = useState("")
  const [mortgageAmount, setMortgageAmount] = useState("")
  const [interestRate, setInterestRate] = useState("")

  // Solutions
  const [calcMainPay, setMainPay] = useState("")
  const [calcYrOne, setYrOne] = useState("")
  const [calcYrTwo, setYrTwo] = useState("")
  const [calcYrThree, setYrThree] = useState("")
  const [calcYrFive, setYrFive] = useState("")
  const [calcYrTen, setYrTen] = useState("")

  const submitCalculation = async e => {
    e.preventDefault()

    // Validate Fields
    const validatedMortgageTerm = await validateField(
      mortgageTerm,
      setMortgageTerm
    )
    const validatedPaymentFrequency = await validateField(
      paymentFrequency,
      setPaymentFrequency
    )
    const validatedAmortizationPeriod = await validateField(
      amortizationPeriod,
      setAmortizationPeriod
    )
    const validatedMortgageAmount = await validateField(
      mortgageAmount,
      setMortgageAmount
    )
    const validatedInterestRate = await validateField(
      interestRate,
      setInterestRate
    )

    // If valid, create all values

    if (
      validatedMortgageTerm &&
      validatedPaymentFrequency &&
      validatedAmortizationPeriod &&
      validatedMortgageAmount &&
      validatedInterestRate
    ) {
      calculateValues()
    }
  }

  const validateField = (field, setValue) => {
    let int = parseFloat(field)

    if (field === "") {
      setValue({ ...field.values, error: "Please enter a value" })
      return false
    } else if (isNaN(int)) {
      setValue({ ...field.values, error: "Please enter a number" })
      return false
    } else {
      setValue(int)
      return true
    }
  }

  const calculateValues = () => {
    // Variables
    let freq = paymentFrequency
    let amort = amortizationPeriod
    let mortgage = mortgageAmount
    let intrate = interestRate
    let payment = calcPay(mortgage, amort, intrate, 2, freq)

    // Functional JS
    function calcRdefine(intrate, compound, freq) {
      return Math.pow(1.0 + intrate / 100 / compound, compound / freq) - 1.0
    }

    function calcBal(mortgage, intrate, compound, freq, payment, term) {
      let rdefine = calcRdefine(intrate, compound, freq)
      // console.log(rdefine)
      return (
        mortgage * Math.pow(1.0 + rdefine, term) -
        (payment * (Math.pow(1.0 + rdefine, term) - 1.0)) / rdefine
      )
    }

    function calcPay(MORTGAGE, AMORT, INRATE, COMPOUND, FREQ) {
      let compound = COMPOUND / 12
      let monTime = AMORT * 12
      let RATE = (INRATE * 1.0) / 100
      let yrRate = RATE / COMPOUND
      let rdefine = Math.pow(1.0 + yrRate, compound) - 1.0
      let PAYMENT =
        (MORTGAGE * rdefine * Math.pow(1.0 + rdefine, monTime)) /
        (Math.pow(1.0 + rdefine, monTime) - 1.0)
      if (FREQ === 12) {
        return PAYMENT
      }
      if (FREQ === 26 || FREQ === 24) {
        return PAYMENT / 2.0
      }
      if (FREQ === 52) {
        return PAYMENT / 4.0
      }
    }

    // Mathemagic
    let mainPay = payment
    let yrOne =
      "$" + calcBal(mortgage, intrate, 2, freq, payment, 12 / (12 / freq))
    let yrTwo =
      "$" + calcBal(mortgage, intrate, 2, freq, payment, 24 / (12 / freq))
    let yrThree =
      "$" + calcBal(mortgage, intrate, 2, freq, payment, 36 / (12 / freq))
    let yrFive =
      "$" + calcBal(mortgage, intrate, 2, freq, payment, 60 / (12 / freq))
    let yrTen =
      "$" + calcBal(mortgage, intrate, 2, freq, payment, 120 / (12 / freq))

    // Set the values
    setMainPay(mainPay)
    setYrOne(yrOne)
    setYrTwo(yrTwo)
    setYrThree(yrThree)
    setYrFive(yrFive)
    setYrTen(yrTen)
  }

  return (
    <Container className="mt-20 md:w-4/5 mx-auto">
      <Paper elevation={3}>
        <form>
          <Grid container className="mortgage-calculator">
            <Grid item md={7} sm={12} className="calc-fields">
              <div className="input-field">
                <label htmlFor="mortgageTerm">Mortgage Term:</label>
                <Error>{mortgageTerm.error}</Error>
                <select
                  id="mortgageTerm"
                  className="select-box"
                  value={mortgageTerm}
                  onChange={e => {
                    const selectedTerm = e.target.value
                    setMortgageTerm(selectedTerm)
                  }}
                >
                  <option value="0" hidden selected>
                    --Choose One--
                  </option>
                  <option value="6">6 Months</option>
                  <option value="12">1 Year</option>
                  <option value="24">2 Years</option>
                  <option value="36">3 Years</option>
                  <option value="60">5 Years</option>
                  <option value="84">7 Years</option>
                  <option value="120">10 Years</option>
                </select>
              </div>
              <div className="input-field">
                <label
                  htmlFor="paymentFrequency"
                >Payment Frequency:</label>
                <Error>{paymentFrequency.error}</Error>
                <select
                id="paymentFrequency"
                  value={paymentFrequency}
                  onChange={e => {
                    const selectedFrequency = e.target.value
                    setPaymentFrequency(selectedFrequency)
                  }}
                >
                  <option value="0" hidden selected>
                    --Choose One--
                  </option>
                  <option value="12">Monthly</option>
                  <option value="24">Semi-Monthly</option>
                  <option value="26">Bi-Weekly</option>
                  <option value="52">Weekly</option>
                </select>
              </div>
              <div className="input-field">
                <label
                  htmlFor="amortizationPeriod"
                >Amortization Period:</label>
                <Error>{amortizationPeriod.error}</Error>
                <input
                id="amortizationPeriod"
                  type="number"
                  onChange={e => setAmortizationPeriod(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label
                  htmlFor="mortgageAmount"
                >Mortgage Amount:</label>
                <Error>{mortgageAmount.error}</Error>
                <input
                id="mortgageAmount"
                  type="number"
                  onChange={e => setMortgageAmount(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label
                  htmlFor="interestRate"
                >Interest Rate:</label>
                <Error>{interestRate.error}</Error>
                <input
                id="interestRate"
                  type="number"
                  onChange={e => setInterestRate(e.target.value)}
                />
              </div>
              <div className="blue-button">
                <SubmitButton onClick={e => submitCalculation(e)}>
                  <span>calculate</span>
                </SubmitButton>
              </div>
            </Grid>
            <Grid item md={5} xs={12} className="calc-totals">
              <div className="totals text-center mb-20">
                <h3 className="white-text">
                  {numeral(calcMainPay).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Mortgage Payment</h6>
              </div>
              <div className="mega-calc">
                <div className="totals flex-item">
                  <h5 className="white-text">
                    {numeral(calcYrOne).format("$0,0.00")}
                  </h5>
                  <h6 className="l-orange-text">1 Year</h6>
                </div>
                <div className="totals flex-item">
                  <h5 className="white-text">
                    {numeral(calcYrTwo).format("$0,0.00")}
                  </h5>
                  <h6 className="l-orange-text">2 Year</h6>
                </div>
                <div className="totals flex-item">
                  <h5 className="white-text">
                    {numeral(calcYrThree).format("$0,0.00")}
                  </h5>
                  <h6 className="l-orange-text">3 Year</h6>
                </div>
                <div className="totals flex-item">
                  <h5 className="white-text">
                    {numeral(calcYrFive).format("$0,0.00")}
                  </h5>
                  <h6 className="l-orange-text">5 Year</h6>
                </div>
                <div className="totals flex-item">
                  <h5 className="white-text">
                    {numeral(calcYrTen).format("$0,0.00")}
                  </h5>
                  <h6 className="l-orange-text">10 Year</h6>
                </div>
              </div>
              <div className="totals white-text">
                <Disclaimer />
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}

export default MaximumMortgage
