import React from 'react'

export default function OneCol(props) {
    return (
        <div>
            <div className="one-col sm:text-center">
                <h3 className={` mb-4 text-${props.color}`}>{props.title}</h3>
                <p className="md:w-9/12 mx-auto">{props.description}</p>                
            </div>
        </div>
    )
}