import React from "react"
import { Container } from "@material-ui/core"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import BlueSection from "../components/website/BlueSection"
import Parallax from "../components/website/Parallax"
import Calculator from "../components/website/calculators/index"

import Footer from "../images/calculators/calculators-cta.jpg"
import blueBG from "../images/calculators/calculators-background.svg"

export default function Calculators({ data }) {
  return (
    <Layout>
      <SEO title="Calculators" />
      {data.allStrapiCalculators.edges.map(({ node }) => (
        <>
          <Intro
            key={node.calculators_intro.id}
            title={node.calculators_intro.intro_title}
            text={node.calculators_intro.intro_description}
            color={node.calculators_intro.intro_color}
            img={node.calculators_intro.Intro_hero.publicURL}
          />
          <BlueSection
            key={node.calculators_bluesection.id}
            title={node.calculators_bluesection.Bluesection_title}
            description={
              node.calculators_bluesection.bluesection_title_description
            }
            order="right"
            intro={true}
            background={blueBG}
          />
          <div className="md:m-32">
            <Container className="mt-16">
              <Calculator />
            </Container>
          </div>
          <Parallax
            title="Need help? Talk to us today. "
            link="/contact"
            button="contact us"
            order="right"
            image={Footer}
          />
        </>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Calculators {
    allStrapiCalculators {
      edges {
        node {
          calculators_intro {
            id
            intro_title
            intro_description
            intro_color
            Intro_hero {
              publicURL
            }
          }
          calculators_bluesection {
            id
            Bluesection_title
            bluesection_title_description
          }
        }
      }
    }
  }
`
