import React, { useState } from "react"
import { Container, Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import numeral from "numeral"
import Disclaimer from "./disclaimer"

const Error = styled.p`
  display: flex;
  width: 250px;
  padding: 0.5rem 0;
  margin: 0;
  font-weight: bold;
`
const SubmitButton = styled.button`
  -webkit-appearance: none;
  background: transparent;
  transition: 0.3s ease;
  border: 2px solid #2c5282;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`

const PaymentAnalyzer = () => {
  const [mortgageAmount, setMortgageAmount] = useState("")
  const [interestRate, setInterestRate] = useState("")
  const [amortization, setAmortization] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState("")
  const [biWeeklyPayment, setBiWeeklyPayment] = useState("")
  const [weeklyPayment, setWeeklyPayment] = useState("")

  const submitCalculation = async e => {
    e.preventDefault()

    // Validate Fields
    const validatedPrice = await validateField(
      mortgageAmount,
      setMortgageAmount
    )
    const validatedinterestRate = await validateField(
      interestRate,
      setInterestRate
    )
    const validatedApr = await validateField(amortization, setAmortization)

    // Calculate Values
    if (validatedPrice && validatedinterestRate && validatedApr) {
      console.log("Form is fully validated.")
      calculateValues()
    }
  }

  const validateField = (field, setValue) => {
    let int = parseFloat(field)

    if (field === "" || field === 0) {
      setValue({ ...field.values, error: "Please enter a value below" })
      return false
    } else if (isNaN(int)) {
      setValue({ ...field.values, error: "Please enter a number below" })
      return false
    } else {
      setValue(int)
      return true
    }
  }

  const calculateValues = () => {
    let principle = mortgageAmount
    let monthlyInterest = interestRate / 100 / 12
    let numberOfPayments = amortization * 12
    let monthlyPrice =
      (principle *
        [monthlyInterest * (1 + monthlyInterest) ** numberOfPayments]) /
      [(1 + monthlyInterest) ** numberOfPayments - 1]
    setMonthlyPayment(monthlyPrice)
    setBiWeeklyPayment(monthlyPrice / 2)
    setWeeklyPayment(monthlyPrice / 4)
  }

  return (
    <Container>
      <Paper elevation={3}>
        <form>
          <Grid container className="mortgage-calculator">
            <Grid item md={7} sm={12} className="calc-fields">
              <div className="input-field">
                <label htmlFor="mortgage-amount">Mortgage Amount</label>
                <Error>{mortgageAmount.error}</Error>
                <input
                  id="mortgageAmount"
                  type="number"
                  onChange={e => setMortgageAmount(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="interestRate">Mortgage Interest Rate</label>
                <Error>{interestRate.error}</Error>
                <input
                  id="interestRate"
                  type="number"
                  onChange={e => setInterestRate(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="amortization">Amortization Period</label>
                <Error>{amortization.error}</Error>
                <input
                  id="amortization"
                  type="number"
                  onChange={e => setAmortization(e.target.value)}
                />
              </div>
              <div className="blue-button">
                <SubmitButton onClick={e => submitCalculation(e)}>
                  <span>calculate</span>
                </SubmitButton>
              </div>
            </Grid>
            <Grid item md={5} xs={12} className="calc-totals">
              <div className="totals">
                <h3 className="white-text">
                  {numeral(monthlyPayment).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Total Monthly Payments</h6>
              </div>
              <div className="totals">
                <h3 className="white-text">
                  {numeral(biWeeklyPayment).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Total Bi-Weekly Payments</h6>
              </div>
              <div className="totals">
                <h3 className="white-text">
                  {numeral(weeklyPayment).format("$0,0.00")}
                </h3>
                <h6 className="l-orange-text">Total Weekly Payments</h6>
              </div>
              <div className="totals white-text">
                <Disclaimer />
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}

export default PaymentAnalyzer
