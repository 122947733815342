import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import OneCol from "../one-col"
import PaymentAnalyzer from "../../calculators/paymentanalyzer"
import MortgageCalculator from "../../calculators/mortgageCalculator"
import MortgageAnalyzer from "../../calculators/mortgageAnalyzer"

export default function Calculators() {
  return (
    <Tabs className="mb-40" id="calculate">
      <TabList>
        <Tab>Maximum Mortgage</Tab>
        <Tab>Mortgage Analyzer</Tab>
        <Tab>Payment Analyzer</Tab>
      </TabList>
      <TabPanel>
        <OneCol
          title="Maximum Mortgage"
          color="blue"
          description="Calculate the maximum mortgage amount you would qualify for based on your income. This is a great tool for planning a home or condo purchase."
        />
        <div className="mt-20 md:w-4/5 mx-auto">
          <MortgageCalculator />
        </div>
      </TabPanel>
      <TabPanel>
        <OneCol
          title="Mortgage Analyzer"
          color="blue"
          description="This tool helps you to calculate your mortgage payment, create an amortization schedule and help forecast what you will owe based on the term and amortization of your mortgage (e.g. 1, 2, 3, 4, 5, 7 and 10 years)."
        />
        <MortgageAnalyzer />
      </TabPanel>
      <TabPanel>
        <OneCol
          title="Payment Analyzer"
          color="blue"
          description="Calculate your mortgage payment for the following payment frequencies: weekly, bi-weekly, weekly accelerated, bi-weekly accelerated, semi-monthly and monthly. Discover ways to reduce the amount of interest paid and how to reduce your amortization by choosing a different payment frequency."
        />
        <div className="mt-20 md:w-4/5 mx-auto">
          <PaymentAnalyzer />
        </div>
      </TabPanel>
    </Tabs>
  )
}
