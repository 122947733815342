import React from 'react'
import { Container, Grid } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

export default function BlueSection(props) {
    
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '20%'
      })  

    let backgroundImage = {
        height: "100%",
        position: "absolute",
        right: "0",
        backgroundPosition: "center right",
        width: "60%",
        opacity: "0.5",
        backgroundImage: `url(${props.background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
      };
  
    return (
        <div className={`parallax ${props.smalltitle ? 'white-section' : 'blue-section' } ${props.intro === true ? 'negative-index' : '' }`}>
            <Container>
            <motion.div className="fade-in" ref={ref} animate={{ opacity: inView ? 1 : 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
                {props.background ? 
                    <div className="blue-bgimg" style={ backgroundImage }>&nbsp;</div> :
                '' }
                <Grid container className={`${props.order === 'right' ? 'flex-row' : 'flex-row-reverse' }`}>
                    <Grid item md={6} sm={12} className={`parallax-text ${props.smalltitle ? '' : 'white-text' }`}>
                        {props.smalltitle ? 
                        <>
                            <h6 className={`${props.smalltitle ? 'blue-text' : 'white-text' }`}>{props.smalltitle}</h6>
                            <h3 className={`w-4/5 ${props.smalltitle ? 'orange-text' : ''}`} dangerouslySetInnerHTML={{__html: `${props.title}`}} />
                        </>
                        : ''}
                        <ReactMarkdown>{props.description}</ReactMarkdown>
                    </Grid>
                    {props.component ? 
                    <Grid item md={6} sm={12} className={`${props.component ? 'flex items-center justify-center' : 'blank-space' }`}>
                        {props.component}
                    </Grid> 
                    : ''
                    }
                </Grid>
                </motion.div>
            </Container>
        </div>
    )
}